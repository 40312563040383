<template>
    <div class="tab_wrapper">
        <ul class="d_flex">
            <li
                v-for="(el, idx) in tabList"
                :key="idx"
                :class="{ active: currentTab === el.value }"
                :style="{ color:'#0D0C0C',width: widthHandler + '%' }"
                @click="tabUpdater(el.value)"
            >
                {{ el.name }}
            </li>
            <div
                class="orange_line"
                :style="{ left: lineHandler + '%', width: widthHandler + '%' }"
            ></div>
        </ul>
        <div class="border_bottom"></div>
    </div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        tabList: {
            type: Array,
            required: true,
        },
        currentTab: {
            type: String,
            required: true,
        },
    },
    computed: {
        lineHandler() {
            const idx = this.tabList.findIndex((item) => {
                return item.value === this.currentTab;
            });
            return idx * this.widthHandler;
        },
        widthHandler() {
            return 100 / this.tabList.length;
        },
    },
    mounted() {
        // console.log(this.currentTab);
    },
    methods: {
        tabUpdater(value) {
            this.$emit('update', value);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.tab_wrapper {
    height: 45px;
    align-items: top;
    /* border-bottom: ; */
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
    ul {
        width: calc(100% - 32px);
        padding: 10px 0 15px;
        position: relative;
        margin: 0 auto;
        list-style: none;

        li {
            flex-grow: 2;
            line-height: 20px;
            &.active {
                color:#FF9F4B !important;
            }
            // &.active {
            //     border-bottom: 5px $color_main_first solid;
            // }
        }
        .orange_line {
            position: absolute;
            bottom: 0;
            height: 5px;
            background-color: $color_main_first;
            transition: all 0.3s;
        }
    }
}
</style>
